/**
 * TODO: Perdir los enlaces a las redes sociales
 * NOTE: entre los 100px y los 768px se podria bajar el menu
 */

import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function Header() {
	const location = useLocation();

	useEffect(() => {
		const menu = document.querySelector('.navWrapper');
		menu.classList.remove('active');
		window.scrollTo(0, 0);
	}, [location]);

	const toggleMenu = () => {
		const menu = document.querySelector('.navWrapper');
		menu.classList.toggle('active');
	};

	return (
		<header>
			<figure>
				<Link to="/">
					<img src="/images/logo.png" alt="The Peak Group Logo" />
				</Link>
			</figure>
			<div className="navWrapper">
				<nav>
					<figure>
						<Link to="/">
							<img src="/images/logo.png" alt="The Peak Group Movil Logo" />
						</Link>
					</figure>
					<ul>
						<li>
							<Link to="/about-us" alt="About us page">
								ABOUT US
							</Link>
						</li>
						<li>
							<Link to="/programs" alt="Programs page">
								PROGRAMS
							</Link>
						</li>
						<li>
							<Link to="/staff" alt="Staff page">
								STAFF
							</Link>
						</li>
						<li>
							<Link to="/carrers" alt="Carrers Page">
								CAREERS
							</Link>
						</li>
						{/* <li>
							<Link to="/gallery" alt="Gallery Page">
								GALLERY
							</Link>
						</li> */}
						<li>
							<Link to="/contact" alt="Contact Us Page">
								CONTACT
							</Link>
						</li>
					</ul>
					<div className="social-networks">
						<a
							href="https://www.facebook.com/profile.php?id=61553873309329&mibextid=LQQJ4d"
							title="Facebook"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src="/svgs/icons/facebook.svg" alt="Facebook Icon" />
						</a>
						<a
							href="https://instagram.com"
							title="Instagram"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src="/svgs/icons/instagram.svg" alt="Instagram Icon" />
						</a>
					</div>
				</nav>
				<span className="bg" onClick={toggleMenu}></span>
			</div>
			<div className="menu-icon">
				<figure onClick={toggleMenu}>
					<img src="/svgs/icons/icons8-menu-48.svg" alt="Hamburger Menu Icon" />
				</figure>
			</div>
		</header>
	);
}
