import H1 from '../components/H1';

export default function Carrers() {
	return (
		<div id="carrer" className={['degradado']}>
			<H1 title="CAREERS" bgColorClass="bg-orange" />
			<section>
				<article>
					<h2>WE'RE HIRING COACHES</h2>
					<h3>GENERAL REQUIREMENTS / INFORMATION</h3>
					<ul>
						<li>- KNOWLEDGE OF VARIOUS SPORTS</li>
						<li>- EXPERIENCE WORKING WITH CHILDREN</li>
						<li>- STRONG WORK ETHIC</li>
						<li>- SUPERB COMMUNICATION SKILLS</li>
						<li>- WORK SCHEDULE FLEXIBILITY</li>
						<li>- PASSION FOR YOUTH DEVELOPMENT</li>
						<li>- 48 COLLEGE UNITS PREFERRED</li>
						<li>- BACKGROUND CHECK REQUIRED</li>
						<li>- HOURLY RATE $20-$25</li>
					</ul>
				</article>
				<figure>
					<img src="/images/carrers-main.png" alt="Carriers Main" />
					<figcaption>
						SEND YOUR RESUME TO
						<br />
						<a href="mailto:hr@thepeakgroup.org">HR@THEPEAKGROUP.ORG</a>{' '}
					</figcaption>
				</figure>
			</section>
		</div>
	);
}
