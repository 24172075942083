import H1 from '../components/H1';

export default function AboutUs() {
	return (
		<>
			<div id="about" className={['degradado']}>
				<H1 title="WHAT IS PEAK?" bgColorClass="bg-yellow" icon="/svgs/icons/montain.svg" />
				<figure>
					<img src="/images/about-main.jpg" alt="main about us" />
				</figure>
				<article>
					<p>
						PEAK USES MULTI-SPORT PROGRAMS AND ENRICHMENT ACTIVITIES TO BENEFIT CHILDREN AGED 6-14
						YEARS PHYSICALLY, MENTALLY, SOCIALLY, AND EMOTIONALLY.
						<br />
						OUR HIGHLY QUALIFIED AND TRAINED COACHES INTRODUCE CHILDREN TO THE FUNDAMENTALS OF
						MULTIPLE SPORTS IN A FUN AND STRUCTURED SETTINGS.
						<br />
						CHILDREN IN OUR PROGRAMS ROUTINELY DEVELOP NEW SKILLS AND PROFOUND LOVE FOR SPORTS AND
						FITNESS.
					</p>
				</article>
			</div>
		</>
	);
}
