import { useState } from 'react';

export default function Contact() {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [message, setMessage] = useState('');

	const sendForm = evt => {
		evt.preventDefault();
		evt.target.classList.add('sended');

		fetch('/mail.php', {
			method: 'POST',
			body: JSON.stringify({
				send: true,
				name: name,
				email: email,
				phone: phone,
				message: message,
			}),
		});
	};

	return (
		<div id="contact" className={['degradado']}>
			<section>
				<figure>
					<img src="/images/contact-main.png" alt="Contact Main" />
				</figure>
				<article>
					<span className="bg-image">
						<img src="/images/contact-bg.png" alt="Contact BG" />
					</span>
					<div className="phone">
						<figure>
							<img src="/svgs/icons/whatsapp.svg" alt="Phone Icon" />
						</figure>
						<hgroup>
							<h4>PHONE</h4>
							<h5>
								<a href="tel:7603598269">760-359-8269</a>
							</h5>
						</hgroup>
					</div>
					<div className="email">
						<figure>
							<img src="/svgs/icons/email.svg" alt="Email Icon" />
						</figure>
						<hgroup>
							<h4>EMAIL</h4>
							<h5>
								<a href="mailto:contact@thepeakgroup.org">CONTACT@THEPEAKGROUP.ORG</a>
							</h5>
						</hgroup>
					</div>
					<form onSubmit={sendForm}>
						<div className="field">
							<label htmlFor="name">NAME:</label>
							<input onChange={evt => setName(evt.target.value)} type="text" id="name" />
						</div>
						<div className="field">
							<label htmlFor="email">EMAIL:</label>
							<input onChange={evt => setEmail(evt.target.value)} type="email" id="email" />
						</div>
						<div className="field">
							<label htmlFor="phone">PHONE:</label>
							<input onChange={evt => setPhone(evt.target.value)} type="tel" id="phone" />
						</div>
						<div className="field">
							<label htmlFor="comments">COMMENTS:</label>
							<textarea onChange={evt => setMessage(evt.target.value)} id="comments"></textarea>
						</div>
						<div className="btn">
							<button>SEND</button>
						</div>
						<div className="message">
							Thank you for contacting us, we will respond shortly to all your comments or
							questions.
						</div>
					</form>
				</article>
			</section>
		</div>
	);
}
