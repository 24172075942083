import H1 from '../components/H1';
import { Link } from 'react-router-dom';

export default function HomePage() {
	return (
		<div id="index" className={['degradado']}>
			<section className="banner">
				<figure>
					<img src="/images/index-banner.png" alt="Index banner" />
				</figure>
				<article>
					<h2>
						ENRICHMENT &<br />
						ATHLETICS
						<br />
						FOR <img src="/svgs/index/kids.svg" alt="kids text" />
					</h2>

					<div className="slogan">
						EMPOWERING YOUNG MINDS THROUGH ACTIVE
						<br />
						LEARNING AND ATHLETIC EXCELLENCE
					</div>
					<div className="buttons">
						<Link to="/about-us">ABOUT US</Link>
						<Link to="/gallery">GALLERY</Link>
						<Link to="/contact">CONTACT</Link>
					</div>
				</article>
			</section>

			<div className="whiteLine"></div>

			<section className="mission-vision">
				<article className="vision">
					<H1
						title={'MISSION STATEMENT'}
						bgColorClass={'bg-blue'}
						icon={'/svgs/icons/mission.svg'}
					/>
					<p>
						AT PEAK, OUR MISSION IS TO CULTIVATE A GENERATION OF WELL-ROUNDED INDIVIDUALS WHO EXCEL
						NOT ONLY ACADEMICALLY BUT ALSO ATHLETICALLY AND SOCIALLY. WE AIM TO INSTILL VALUES OF
						TEAMWORK, DISCIPLINE, AND RESILIENCE THROUGH ENGAGING SPORTS AND ENRICHMENT ACTIVITIES
					</p>
				</article>
				<article className="vision">
					<H1
						title={'VISION STATEMENT'}
						bgColorClass={'bg-orange'}
						icon={'/svgs/icons/vision.svg'}
					/>
					<p>EMPOWERING YOUNG MINDS THROUGH ACTIVE LEARNING AND ATHLETIC EXCELLENCE</p>
				</article>
			</section>

			<section className="classes">
				<div className="title">
					<div className="lines">
						<span className="yellow"></span>
						<span className="blue"></span>
						<span className="orange"></span>
					</div>
					<figure>
						<img src="/images/programs-classes-title.png" alt="classes title" />
					</figure>
				</div>
				<article>
					<ul>
						<li>
							<span>
								<img src="/svgs/classes/lacrosse-blue.svg" alt="lacrosse" />
							</span>
							LACROSSE
						</li>
						<li>
							<span>
								<img src="/svgs/classes/badminton-blue.svg" alt="badminton" />
							</span>
							BADMINTON
						</li>
						<li>
							<span>
								<img src="/svgs/classes/basketball-blue.svg" alt="basketball" />
							</span>
							BASKETBALL
						</li>
						<li>
							<span>
								<img src="/svgs/classes/capture-the-flag-blue.svg" alt="capture the flag" />
							</span>
							CAPTURE THE FLAG
						</li>
						<li>
							<span>
								<img src="/svgs/classes/pickleball-blue.svg" alt="pickleball" />
							</span>
							PICKLEBALL
						</li>
						<li>
							<span>
								<img src="/svgs/classes/spikeball-blue.svg" alt="spikeball" />
							</span>
							SPIKEBALL
						</li>
						<li>
							<span>
								<img src="/svgs/classes/dodgeball-blue.svg" alt="dodgeball" />
							</span>
							DODGEBALL
						</li>
						<li>
							<span>
								<img src="/svgs/classes/flag-football-blue.svg" alt="flag-football" />
							</span>
							FLAG FOOTBALL
						</li>
						<li>
							<span>
								<img src="/svgs/classes/cheers-blue.svg" alt="cheers" />
							</span>
							CHEER
						</li>
					</ul>
					<ul>
						<li>
							<span>
								<img src="/svgs/classes/kickball-blue.svg" alt="kickball" />
							</span>
							KICKBALL
						</li>
						<li>
							<span>
								<img src="/svgs/classes/speed-and-agility-blue.svg" alt="speed & agility" />
							</span>
							SPEED & AGILITY
						</li>
						<li>
							<span>
								<img src="/svgs/classes/ultimate-frisbee-blue.svg" alt="ultimate frisbee" />
							</span>
							ULTIMATE FRISBEE
						</li>
						<li>
							<span>
								<img src="/svgs/classes/handball-blue.svg" alt="handball" />
							</span>
							HANDBALL
						</li>
						<li>
							<span>
								<img src="/svgs/classes/soccer-blue.svg" alt="soccer" />
							</span>
							SOCCER
						</li>
						<li>
							<span>
								<img src="/svgs/classes/yoga-blue.svg" alt="yoga" />
							</span>
							YOGA
						</li>
						<li>
							<span>
								<img src="/svgs/classes/wiffle-ball-blue.svg" alt="wiffleball" />
							</span>
							WIFFLEBALL
						</li>
						<li>
							<span>
								<img src="/svgs/classes/chess-blue.svg" alt="chess" />
							</span>
							CHESS & CHECKERS
						</li>
						<li>
							<span>
								<img src="/svgs/classes/more-blue.svg" alt="many more" />
							</span>
							MANY MORE
						</li>
					</ul>
				</article>
			</section>

			<section className="gallery">
				<h2>GALLERY</h2>
				<figure className="galleryImage">
					<img src="/images/gallery.png" alt="" />
				</figure>
				<figure className="peakTitle">
					<img src="/svgs/index/peak-txt.svg" alt="peak text" />
				</figure>
			</section>
		</div>
	);
}
