/**
 * @param title<string>
 * @param bgColorClass<bgColorClass<null|bg-yellow|bg-blue|bg-orange>
 * @param styles<object>
 * @param icon<string> The icon path
 */
export default function H1({ title, bgColorClass, styles, icon }) {
	return (
		<h1 className={bgColorClass} style={styles}>
			{title}
			{icon && (
				<span>
					<img src={icon} alt="h1 icon" />
				</span>
			)}
		</h1>
	);
}
