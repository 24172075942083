import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes } from 'react-router-dom';

// import router from './routers/index';
import './_sass/main.scss';

import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutUs from './pages/AboutUs';
import Programs from './pages/Programs';
import Carrers from './pages/Carrers';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import Staff from './pages/Staff';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Header />
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/about-us" element={<AboutUs />} />
				<Route path="/programs" element={<Programs />} />
				<Route path="/staff" element={<Staff />} />
				<Route path="/carrers" element={<Carrers />} />
				<Route path="/gallery" element={<Gallery />} />
				<Route path="/contact" element={<Contact />} />
			</Routes>
			<Footer />
		</BrowserRouter>
		{/* <RouterProvider router={router}> */}
	</React.StrictMode>
);
