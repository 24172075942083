import { Link } from 'react-router-dom';

export default function Footer() {
	return (
		<footer>
			<div>
				<menu>
					<div className="title">INFO</div>
					<Link to="/about-us">ABOUT US</Link>
					<Link to="/programs">PROGRAMS</Link>
					<Link to="/staff">STAFF</Link>
					<Link to="/contact">CONTACT</Link>
				</menu>
				<menu>
					<div className="title">CONTACT US</div>
					<a href="tel:7603598269">760-359-8269</a>
					<div className="social-networks">
						<a
							href="https://www.facebook.com/profile.php?id=61553873309329&mibextid=LQQJ4d"
							title="Facebook"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src="/svgs/icons/facebook.svg" alt="Facebook Icon" />
						</a>
						<a
							href="https://www.instagram.com/peak_imperial_valley/"
							title="Instagram"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src="/svgs/icons/instagram.svg" alt="Instagram Icon" />
						</a>
					</div>
				</menu>
				{/* <menu>
					<div className="title">GALLERY</div>
					<Link to="/gallery">VISIT HERE</Link>
				</menu> */}
			</div>
			<figure>
				<Link to="/" title="Go to homepage">
					<img src="/svgs/plogo.svg" alt="Only P logo" />
				</Link>
			</figure>
		</footer>
	);
}
