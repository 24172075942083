import H1 from '../components/H1';
import '../_sass/_programs.scss';

export default function Programs() {
	return (
		<div id="programs" className={['degradado']}>
			<H1 title="PROGRAMS" styles={{ width: '100%', marginBottom: '0' }} bgColorClass="bg-blue" />
			<section className="banner">
				<article>
					<p>
						AT PEAK, OUR HIGHLY TRAINED AND QUALIFIED COACHES ARE READY TO TEACH OUR YOUTH THE
						FUNDAMENTALS OF SPORTS AND PROMOTE PHYSICAL, MENTAL, SOCIAL, AND EMOTIONAL WELL-BEING
						THROUGH MULTI-SPORT PROGRAMS AND ENRICHMENT ACTIVITIES.
					</p>
					<ul>
						<li>
							<span>
								<img src="/svgs/programs1.svg" alt="extended days sports" />
							</span>
							EXTENDED DAYS SPORTS
						</li>
						<li>
							<span>
								<img src="/svgs/programs2.svg" alt="clinics and leagues" />
							</span>
							CLINICS AND LEAGUES
						</li>
						<li>
							<span>
								<img src="/svgs/programs3.svg" alt="camps" />
							</span>
							CAMPS
						</li>
					</ul>
					<figure>
						<img src="/svgs/plogo-white.svg" alt="" />
					</figure>
				</article>
				<figure className="bg">
					<img src="/images/programs-main.png" alt="programs main" />
				</figure>
			</section>
			<section className="classes">
				<figure>
					<img src="/images/programs-classes-title.png" alt="Classes offered" />
				</figure>

				<ul>
					<li>
						<span>
							<img src="/svgs/classes/lacrosse.svg" alt="lacrosse" />
						</span>
						LACROSSE
					</li>
					<li>
						<span>
							<img src="/svgs/classes/badminton.svg" alt="badminton" />
						</span>
						BADMINTON
					</li>
					<li>
						<span>
							<img src="/svgs/classes/basketball.svg" alt="basketball" />
						</span>
						BASKETBALL
					</li>
					<li>
						<span>
							<img src="/svgs/classes/capture-the-flag.svg" alt="capture the flag" />
						</span>
						CAPTURE THE FLAG
					</li>
					<li>
						<span>
							<img src="/svgs/classes/pickleball.svg" alt="pickleball" />
						</span>
						PICKLEBALL
					</li>
					<li>
						<span>
							<img src="/svgs/classes/spikeball.svg" alt="spikeball" />
						</span>
						SPIKEBALL
					</li>
					<li>
						<span>
							<img src="/svgs/classes/dodgeball.svg" alt="dodgeball" />
						</span>
						DODGEBALL
					</li>
					<li>
						<span>
							<img src="/svgs/classes/flag-football.svg" alt="flag-football" />
						</span>
						FLAG FOOTBALL
					</li>
					<li>
						<span>
							<img src="/svgs/classes/kickball.svg" alt="kickball" />
						</span>
						KICKBALL
					</li>
					<li>
						<span>
							<img src="/svgs/classes/speed-and-agility.svg" alt="speed & agility" />
						</span>
						SPEED & AGILITY
					</li>
					<li>
						<span>
							<img src="/svgs/classes/ultimate-frisbee.svg" alt="ultimate frisbee" />
						</span>
						ULTIMATE FRISBEE
					</li>
					<li>
						<span>
							<img src="/svgs/classes/handball.svg" alt="handball" />
						</span>
						HANDBALL
					</li>
					<li>
						<span>
							<img src="/svgs/classes/soccer.svg" alt="soccer" />
						</span>
						SOCCER
					</li>
					<li>
						<span>
							<img src="/svgs/classes/yoga.svg" alt="yoga" />
						</span>
						YOGA
					</li>
					<li>
						<span>
							<img src="/svgs/classes/wiffle-ball.svg" alt="wiffleball" />
						</span>
						WIFFLEBALL
					</li>
					<li>
						<span>
							<img src="/svgs/classes/chess.svg" alt="chess" />
						</span>
						CHESS & CHECKERS
					</li>
					<li>
						<span>
							<img src="/svgs/classes/cheers.svg" alt="cheers" />
						</span>
						CHEER
					</li>
					<li>
						<span>
							<img src="/svgs/classes/more.svg" alt="more" />
						</span>
						MANY MORE
					</li>
				</ul>

				<article>
					EMPOWERING YOUNG MINDS THROUGH ACTIVE
					<br /> LEARNING AND ATHLETIC EXCELLENCE
				</article>
			</section>
		</div>
	);
}
