import H1 from '../components/H1';
import '../_sass/_staff.scss';

export default function Staff() {
	return (
		<>
			<div id="staff" className={['degradado']}>
				<H1 title="STAFF" bgColorClass="bg-orange" />
				<figure>
					<img src="/images/staff-main.jpg" alt="main staff" />
				</figure>
				<article>
					<p>
						THE STAFF PORTAL IS FOR CURRENT STAFF ONLY. <br />
						IF YOU ARE APPLYING FOR A POSITION, PLEASE GO TO OUR CAREERS PAGE.
					</p>
					<p>NEED YOUR LOGIN INFORMATION?</p>
					<p>
						PLEASE CONTACT YOUR REGIONAL MANAGER <br />
						OR SITE COORDINATOR FOR YOUR SITE'S <br />
						STAFF PORTAL LOGIN ACCOUNT INFORMATION
					</p>
					<p>THANK YOU!</p>
				</article>
			</div>
		</>
	);
}
